import { combineReducers } from 'redux';
import listDux from './lib/listDux';


const processResponse = ([{ response = [], status, }, [{ payload: { query, isFetching } }]] = []) => {
  if (status == 200)
    return { items: response.data, count: response.data?.length, query, isFetching };
  return { error: 'Server Error', errorMessage: 'Server Error', query, isFetching };
};

const { reducer: $monthlyShipmentsList, actions: monthlyShipmentsList, epics: monthlyShipmentsListEpics } = listDux({
  name: 'monthlyShipmentsQuery',
  url: '/api/reporting/v2/monthlyShipmentsQuery/',
  processResponse,
});

const { reducer: $shipmentsByOriginList, actions: shipmentsByOriginList, epics: shipmentsByOriginListEpics } = listDux({
  name: 'shipmentsByOriginQuery',
  url: '/api/reporting/v2/shipmentsByOriginQuery/',
  processResponse,
});

const { reducer: $shipmentsByDestinationList, actions: shipmentsByDestinationList, epics: shipmentsByDestinationListEpics } = listDux({
  name: 'shipmentsByDestinationQuery',
  url: '/api/reporting/v2/shipmentsByDestinationQuery/',
  processResponse,
});

const { reducer: $shipmentsByCarriersList, actions: shipmentsByCarriersList, epics: shipmentsByCarriersListEpics } = listDux({
  name: 'shipmentsByCarriersQuery',
  url: '/api/reporting/v2/shipmentsByCarriersQuery/',
  processResponse,
});

const { reducer: $shipmentsByForwardersList, actions: shipmentsByForwardersList, epics: shipmentsByForwardersListEpics } = listDux({
  name: 'shipmentsByForwardersQuery',
  url: '/api/reporting/v2/shipmentsByForwardersQuery/',
  processResponse,
});


const { reducer: $shipmentsTeusOverTimeList, actions: shipmentsTeusOverTimeList, epics: shipmentsTeusOverTimeListEpics } = listDux({
  name: 'shipmentAndTEUCountByDateQuery',
  url: '/api/reporting/v2/shipmentAndTEUCountByDateQuery/',
  processResponse,
});

const { reducer: $shipmentGateOutTimeList, actions: shipmentGateOutTimeList, epics: shipmentGateOutTimeListEpics } = listDux({
  name: 'dischargeTimeQuery',
  url: '/api/reporting/v2/dischargeTimeQuery/',
  processResponse,
});

const { reducer: $shipmentsByRouteList, actions: shipmentsByRouteList, epics: shipmentsByRouteListEpics } = listDux({
  name: 'shipmentsBetweenPolPodQuery',
  url: '/api/reporting/v2/shipmentsBetweenPolPodQuery/',
  processResponse,
});

const { reducer: $portToPortTransitTimeList, actions: portToPortTransitTimeList, epics: portToPortTransitTimeListEpics } = listDux({
  name: 'portToPortTransitTimeQuery',
  url: '/api/reporting/v2/portToPortTransitTimeQuery/',
  processResponse,
});



const { reducer: $dwellTimeByPolAndCarrierList, actions: dwellTimeByPolAndCarrierList, epics: dwellTimeByPolAndCarrierListEpics } = listDux({
  name: 'dwellTimeByPolAndCarrierQuery',
  url: '/api/reporting/v2/dwellTimeByPolAndCarrierQuery/',
  processResponse,
});


const { reducer: $onTimePerformanceByCarrierList, actions: onTimePerformanceByCarrierList, epics: onTimePerformanceByCarrierListEpics } = listDux({
  name: 'onTimePerformanceByCarrierQuery',
  url: '/api/reporting/v2/onTimePerformanceByCarrierQuery/',
  processResponse,
});

const { reducer: $overallCarrierArrivalPerformanceList, actions: overallCarrierArrivalPerformanceList, epics: overallCarrierArrivalPerformanceListEpics } = listDux({
  name: 'overallCarrierArrivalPerformanceQuery',
  url: '/api/reporting/v2/carrierPerformanceQuery/',
  processResponse,
});

const { reducer: $overallCarrierDestinationPerformanceList, actions: overallCarrierDestinationPerformanceList, epics: overallCarrierDestinationPerformanceListEpics } = listDux({
  name: 'overallCarrierDestinationPerformanceQuery',
  url: '/api/reporting/v2/carrierPerformanceQuery/',
  processResponse,
});

const { reducer: $performanceByCarrierArrivalList, actions: performanceByCarrierArrivalList, epics: performanceByCarrierArrivalListEpics } = listDux({
  name: 'performanceByCarrierArrivalQuery',
  url: '/api/reporting/v2/carrierPerformanceQuery/',
  processResponse,
});

const { reducer: $carrierDestinationPerformanceList, actions: carrierDestinationPerformanceList, epics: carrierDestinationPerformanceListEpics } = listDux({
  name: 'carrierDestinationPerformanceQuery',
  url: '/api/reporting/v2/carrierPerformanceQuery/',
  processResponse,
});

const { reducer: $carrierPerformanceByOriginList, actions: carrierPerformanceByOriginList, epics: carrierPerformanceByOriginListEpics } = listDux({
  name: 'carrierPerformanceByArrivalQuery',
  url: '/api/reporting/v2/carrierPerformanceQuery/',
  processResponse,
});

const { reducer: $carrierPerformanceByDestinationList, actions: carrierPerformanceByDestinationList, epics: carrierPerformanceByDestinationListEpics } = listDux({
  name: 'carrierPerformanceByDestinationQuery',
  url: '/api/reporting/v2/carrierPerformanceQuery/',
  processResponse,
});

const { reducer: $carrierTransitTimeAccuracyList, actions: carrierTransitTimeAccuracyList, epics: carrierTransitTimeAccuracyListEpics } = listDux({
  name: 'carrierTransitTimeAccuracyQuery',
  url: '/api/reporting/v2/carrierTransitTimeAccuracyQuery/',
  processResponse,
});

const { reducer: $dwellTimePerformancebyPolList, actions: dwellTimePerformancebyPolList, epics: dwellTimePerformancebyPolListEpics } = listDux({
  name: 'dwellTimeQuery',
  url: '/api/reporting/v2/dwellTimeQuery/',
  processResponse,
});

const { reducer: $carrierArrivalPerformanceList, actions: carrierArrivalPerformanceList, epics: carrierArrivalPerformanceListEpics } = listDux({
  name: 'carrierArrivalPerformanceQuery',
  url: '/api/reporting/v2/carrierArrivalPerformanceQuery/',
  processResponse,
});

const { reducer: $carrierLeadTimeList, actions: carrierLeadTimeList, epics: carrierLeadTimeListEpics } = listDux({
  name: 'carrierLeadTimeQuery',
  url: '/api/reporting/v2/carrierLeadTimeQuery/',
});

const { reducer: $rfqByPortLoadingList, actions: rfqByPortLoadingList, epics: rfqByPortLoadingListEpics } = listDux({
  name: 'rfqByPortLoadingList',
  url: '/api/rfq/rfqs/port-summary/',
  processResponse,
});

const { reducer: $rfqByPortDestinationList, actions: rfqByPortDestinationList, epics: rfqByPortDestinationListEpics } = listDux({
  name: 'rfqByPortDestinationList',
  url: '/api/rfq/rfqs/port-summary/',
  processResponse,
});

const { reducer: $rfqByVendorList, actions: rfqByVendorList, epics: rfqByVendorListEpics } = listDux({
  name: 'rfqByVendorList',
  url: '/api/rfq/rfqs/vendor-summary/',
  processResponse,
});

const { reducer: $rfqVendorParticipationList, actions: rfqVendorParticipationList, epics: rfqVendorParticipationListEpics } = listDux({
  name: 'rfqVendorParticipationList',
  url: '/api/rfq/quotes/vendor-participation-summary/',
  processResponse,
});

const { reducer: $rfqVendorAwardedList, actions: rfqVendorAwardedList, epics: rfqVendorAwardedListEpics } = listDux({
  name: 'rfqVendorAwardedList',
  url: '/api/rfq/quotes/vendor-awarded-summary/',
  processResponse,
});

const { reducer: $rfqSingleVendorAwardedList, actions: rfqSingleVendorAwardedList, epics: rfqSingleVendorAwardedListEpics } = listDux({
  name: 'rfqSingleVendorAwardedList',
  url: '/api/rfq/quotes/vendor-awarded/',
  processResponse,
});

const { reducer: $orderRevenueList, actions: orderRevenueList, epics: orderRevenueListEpics } = listDux({
  name: 'orderRevenueList',
  url: '/api/orders/aggregateByRevenue',
  processResponse: ([{ response = [], status, }, [{ payload: { query, isFetching } }]] = []) => {
    const { groupBy } = query;
    if (status == 200)
      return { items: response.data, count: response.data?.length, scope: groupBy, query, isFetching };
    return { error: 'Server Error', errorMessage: 'Server Error', query, isFetching };
  },
  switchRequests: false,
});

const { reducer: $orderLineItemDelayList, actions: orderLineItemDelayList, epics: orderLineItemDelayListEpics } = listDux({
  name: 'orderLineItemDelayList',
  url: '/api/orders/aggregateLineItemDelay',
  processResponse: ([{ response = [], status, }, [{ payload: { query, isFetching } }]] = []) => {
    const { groupBy } = query;
    if (status == 200)
      return { items: response.data, count: response.data?.length, scope: groupBy, query, isFetching };
    return { error: 'Server Error', errorMessage: 'Server Error', query, isFetching };
  },
  switchRequests: false,
});

const { reducer: $orderLineItemPerformanceList, actions: orderLineItemPerformanceList, epics: orderLineItemPerformanceListEpics } = listDux({
  name: 'orderLineItemPerformanceList',
  url: '/api/orders/aggregateLineItemPerformance',
  processResponse: ([{ response = [], status, }, [{ payload: { query, isFetching } }]] = []) => {
    const { groupBy } = query;
    if (status == 200)
      return { items: response.data, count: response.data?.length, scope: groupBy, query, isFetching };
    return { error: 'Server Error', errorMessage: 'Server Error', query, isFetching };
  },
  switchRequests: false,
});

const { reducer: $orderLineItemRevenueList, actions: orderLineItemRevenueList, epics: orderLineItemRevenueListEpics } = listDux({
  name: 'orderLineItemRevenueList',
  url: '/api/orders/aggregateLineItemRevenue',
  processResponse: ([{ response = [], status, }, [{ payload: { query, isFetching } }]] = []) => {
    const { groupBy, scope=groupBy } = query;
    if (status == 200)
      return { items: response.data, count: response.data?.length, scope, query, isFetching };
    return { error: 'Server Error', errorMessage: 'Server Error', query, isFetching };
  },
  setListPayload: ({ items = [], count = 0, error = undefined, errorMessage = '', query = {scope=JSON.stringify(query)}={}, scope, ...payload } = {}) => ({ ...payload, [scope]: { items, count, error, errorMessage, isFetching: false,}, items, count, error, errorMessage, isFetching: false,}),
  switchRequests: false,
});

const { reducer: $shipmentArrivalList, actions: shipmentArrivalList, epics: shipmentArrivalListEpics } = listDux({
  name: 'shipmentArrivalQuery',
  url: '/api/reporting/v2/shipmentArrivalQuery/',
  processResponse,
});

const { reducer: $shipmentArrivalAtOriginList, actions: shipmentArrivalAtOriginList, epics: shipmentArrivalAtOriginListEpics } = listDux({
  name: 'shipmentArrivalAtOriginQuery',
  url: '/api/reporting/v2/shipmentArrivalQuery/',
  processResponse,
});

const { reducer: $shipmentArrivalAtTransitList, actions: shipmentArrivalAtTransitList, epics: shipmentArrivalAtTransitListEpics } = listDux({
  name: 'shipmentArrivalAtTransitQuery',
  url: '/api/reporting/v2/shipmentArrivalQuery/',
  processResponse,
});

const { reducer: $shipmentArrivalAtTransshipmentList, actions: shipmentArrivalAtTransshipmentList, epics: shipmentArrivalAtTransshipmentListEpics } = listDux({
  name: 'shipmentArrivalAtTransshipmentQuery',
  url: '/api/reporting/v2/shipmentArrivalQuery/',
  processResponse,
});

const { reducer: $shipmentArrivalAtPODList, actions: shipmentArrivalAtPODList, epics: shipmentArrivalAtPODListEpics } = listDux({
  name: 'shipmentArrivalAtPODQuery',
  url: '/api/reporting/v2/shipmentArrivalQuery/',
  processResponse,
});

const { reducer: $shipmentApproachingDestination, actions: shipmentApproachingDestination, epics: shipmentApproachingDestinationEpics } = listDux({
  name: 'shipmentApproachingDestination',
  url: '/api/reporting/v2/shipmentApproachingDestinationQuery/',
  processResponse,
});

const { reducer: $shipmentApproachingDestinationbyPOD, actions: shipmentApproachingDestinationbyPOD, epics: shipmentApproachingDestinationbyPODEpics } = listDux({
  name: 'shipmentApproachingDestinationbyPOD',
  url: '/api/reporting/v2/shipmentApproachingDestinationQuery/',
  processResponse,
});

const { reducer: $transshipmentAgeingList, actions: transshipmentAgeingList, epics: transshipmentAgeingListEpics } = listDux({
  name: 'transshipmentAgeingList',
  url: '/api/reporting/v2/transshipmentAgeingQuery/',
  processResponse,
});

const { reducer: $transshipmentAgeingByPodList, actions: transshipmentAgeingByPodList, epics: transshipmentAgeingByPodListEpics } = listDux({
  name: 'transshipmentAgeingByPodList',
  url: '/api/reporting/v2/transshipmentAgeingQuery/',
  processResponse,
});

const { reducer: $demurrageGateOutPendingList, actions: demurrageGateOutPendingList, epics: demurrageGateOutPendingListEpics } = listDux({
  name: 'demurrageGateOutPendingList',
  url: '/api/reporting/v2/demurrageDaysByCarrierQuery/',
  processResponse,
});

const { reducer: $averageDemurrageByCarrierList, actions: averageDemurrageByCarrierList, epics: averageDemurrageByCarrierListEpics } = listDux({
  name: 'averageDemurrageByCarrierList',
  url: '/api/reporting/v2/demurrageDaysByCarrierQuery/',
  processResponse,
});

const { reducer: $demurrageByDestinationList, actions: demurrageByDestinationList, epics: demurrageByDestinationListEpics } = listDux({
  name: 'demurrageByDestinationList',
  url: '/api/reporting/v2/demurrageDaysByDestinationQuery/',
  processResponse,
});

const { reducer: $averageDemurrageByDestinationList, actions: averageDemurrageByDestinationList, epics: averageDemurrageByDestinationListEpics } = listDux({
  name: 'averageDemurrageByDestinationList',
  url: '/api/reporting/v2/demurrageDaysByDestinationQuery/',
  processResponse,
});

const { reducer: $demurrageGatedOutList, actions: demurrageGatedOutList, epics: demurrageGatedOutListEpics } = listDux({
  name: 'demurrageGatedOutList',
  url: '/api/reporting/v2/demurrageDaysQuery/',
  processResponse,
});

const { reducer: $detentionDaysByCarrierList, actions: detentionDaysByCarrierList, epics: detentionDaysByCarrierListEpics } = listDux({
  name: 'detentionDaysByCarrierList',
  url: '/api/reporting/v2/detentionDaysByCarrierQuery/',
  processResponse,
});

const { reducer: $averageDetentionDaysByCarrierList, actions: averageDetentionDaysByCarrierList, epics: averageDetentionDaysByCarrierListEpics } = listDux({
  name: 'averageDetentionDaysByCarrierList',
  url: '/api/reporting/v2/detentionDaysByCarrierQuery/',
  processResponse,
});

const { reducer: $detentionDaysByDestinationList, actions: detentionDaysByDestinationList, epics: detentionDaysByDestinationListEpics } = listDux({
  name: 'detentionDaysByDestinationList',
  url: '/api/reporting/v2/detentionDaysByDestinationQuery/',
  processResponse,
});

const { reducer: $averageDetentionDaysByDestinationList, actions: averageDetentionDaysByDestinationList, epics: averageDetentionDaysByDestinationListEpics } = listDux({
  name: 'averageDetentionDaysByDestinationList',
  url: '/api/reporting/v2/detentionDaysByDestinationQuery/',
  processResponse,
});

const { reducer: $averageTurnaroundTimeList, actions: averageTurnaroundTimeList, epics: averageTurnaroundTimeListEpics } = listDux({
  name: 'averageTurnaroundTimeList',
  url: '/api/reporting/v2/turnaroundTimeQuery/',
  processResponse,
});

const { reducer: $shipmentApproachingTransshipment, actions: shipmentApproachingTransshipment, epics: shipmentApproachingTransshipmentEpics } = listDux({
  name: 'shipmentApproachingTransshipment',
  url: '/api/reporting/v2/shipmentApproachingTransshipment/',
  processResponse,
});

const { reducer: $activeShipmentsAtTransitQuery, actions: activeShipmentsAtTransitQuery, epics: activeShipmentsAtTransitQueryEpics } = listDux({
  name: 'activeShipmentsAtTransitQuery',
  url: '/api/reporting/v2/activeShipmentsAtTransitQuery/',
  processResponse,
});

export const actions = { monthlyShipmentsList, shipmentsByOriginList, shipmentsByDestinationList, shipmentsByCarriersList, shipmentsByForwardersList, shipmentsTeusOverTimeList, shipmentGateOutTimeList, shipmentsByRouteList, portToPortTransitTimeList, dwellTimeByPolAndCarrierList, onTimePerformanceByCarrierList, carrierArrivalPerformanceList, carrierDestinationPerformanceList, overallCarrierArrivalPerformanceList, carrierDestinationPerformanceList, overallCarrierDestinationPerformanceList, carrierPerformanceByOriginList, carrierPerformanceByDestinationList, carrierTransitTimeAccuracyList, dwellTimePerformancebyPolList, performanceByCarrierArrivalList, carrierLeadTimeList, rfqByPortLoadingList, rfqByPortDestinationList, rfqByVendorList, rfqVendorParticipationList,rfqVendorAwardedList,rfqSingleVendorAwardedList, orderRevenueList, orderLineItemDelayList, orderLineItemPerformanceList, orderLineItemRevenueList, shipmentArrivalList, shipmentArrivalAtOriginList, shipmentArrivalAtTransitList, shipmentArrivalAtTransshipmentList, shipmentArrivalAtPODList, shipmentApproachingDestination, shipmentApproachingDestinationbyPOD, transshipmentAgeingList, transshipmentAgeingByPodList, demurrageGateOutPendingList, averageDemurrageByCarrierList, demurrageByDestinationList, averageDemurrageByDestinationList, demurrageGatedOutList, detentionDaysByCarrierList, averageDetentionDaysByCarrierList, detentionDaysByDestinationList, averageDetentionDaysByDestinationList, averageTurnaroundTimeList, shipmentApproachingTransshipment, activeShipmentsAtTransitQuery  };
export const epics = [...monthlyShipmentsListEpics, ...shipmentsByOriginListEpics, ...shipmentsByDestinationListEpics, ...shipmentsByCarriersListEpics, ...shipmentsByForwardersListEpics, ...shipmentsTeusOverTimeListEpics, ...shipmentGateOutTimeListEpics, ...shipmentsByRouteListEpics, ...portToPortTransitTimeListEpics, ...dwellTimeByPolAndCarrierListEpics, ...onTimePerformanceByCarrierListEpics, ...carrierArrivalPerformanceListEpics, ...carrierDestinationPerformanceListEpics, ...overallCarrierArrivalPerformanceListEpics, ...overallCarrierDestinationPerformanceListEpics, ...carrierPerformanceByOriginListEpics, ...carrierPerformanceByDestinationListEpics, ...carrierTransitTimeAccuracyListEpics, ...dwellTimePerformancebyPolListEpics, ...performanceByCarrierArrivalListEpics, ...carrierLeadTimeListEpics, ...rfqByPortLoadingListEpics, ...rfqByPortDestinationListEpics, ...rfqByVendorListEpics, ...rfqVendorParticipationListEpics,...rfqVendorAwardedListEpics,...rfqSingleVendorAwardedListEpics, ...orderRevenueListEpics, ...orderLineItemDelayListEpics, ...orderLineItemPerformanceListEpics, ...orderLineItemRevenueListEpics, ...shipmentArrivalListEpics, ...shipmentArrivalAtOriginListEpics, ...shipmentArrivalAtTransitListEpics, ...shipmentArrivalAtTransshipmentListEpics, ...shipmentArrivalAtPODListEpics, ...shipmentApproachingDestinationEpics, ...shipmentApproachingDestinationbyPODEpics, ...transshipmentAgeingListEpics, ...transshipmentAgeingByPodListEpics, ...demurrageGateOutPendingListEpics,...averageDemurrageByCarrierListEpics, ...demurrageByDestinationListEpics,...averageDemurrageByDestinationListEpics,  ...demurrageGatedOutListEpics, ...detentionDaysByCarrierListEpics, ...averageDetentionDaysByCarrierListEpics, ...detentionDaysByDestinationListEpics, ...averageDetentionDaysByDestinationListEpics, ...averageTurnaroundTimeListEpics, ...shipmentApproachingTransshipmentEpics, ...activeShipmentsAtTransitQueryEpics ];

export default combineReducers({ $monthlyShipmentsList, $shipmentsByOriginList, $shipmentsByDestinationList, $shipmentsByCarriersList, $shipmentsByForwardersList, $shipmentsTeusOverTimeList, $shipmentGateOutTimeList, $shipmentsByRouteList, $portToPortTransitTimeList, $dwellTimeByPolAndCarrierList, $onTimePerformanceByCarrierList, $carrierArrivalPerformanceList, $carrierDestinationPerformanceList, $overallCarrierArrivalPerformanceList, $overallCarrierDestinationPerformanceList, $carrierPerformanceByOriginList, $carrierPerformanceByDestinationList, $carrierTransitTimeAccuracyList, $dwellTimePerformancebyPolList, $performanceByCarrierArrivalList, $carrierLeadTimeList, $rfqByPortLoadingList, $rfqByPortDestinationList, $rfqByVendorList, $rfqVendorParticipationList, $rfqVendorAwardedList, $rfqSingleVendorAwardedList, $orderRevenueList, $orderLineItemDelayList, $orderLineItemPerformanceList, $orderLineItemRevenueList, $shipmentArrivalList, $shipmentArrivalAtOriginList, $shipmentArrivalAtTransitList, $shipmentArrivalAtTransshipmentList, $shipmentArrivalAtPODList, $shipmentApproachingDestination, $shipmentApproachingDestinationbyPOD, $transshipmentAgeingList, $transshipmentAgeingByPodList, $demurrageGateOutPendingList, $averageDemurrageByCarrierList, $demurrageByDestinationList, $averageDemurrageByDestinationList, $demurrageGatedOutList, $detentionDaysByCarrierList, $averageDetentionDaysByCarrierList, $detentionDaysByDestinationList, $averageDetentionDaysByDestinationList, $averageTurnaroundTimeList, $shipmentApproachingTransshipment, $activeShipmentsAtTransitQuery  });
